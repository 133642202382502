import { Commit } from "vuex";

export default {
  state: {
    name: localStorage.getItem("name") || "",
    phone: localStorage.getItem("phone") || "",
    postcode: localStorage.getItem("postcode") || "",
    roadAddress: localStorage.getItem("roadAddress") || "",
    jibunAddress: localStorage.getItem("jibunAddress") || "",
    detailAddress: localStorage.getItem("detailAddress") || "",
    extraAddress: localStorage.getItem("extraAddress") || "",
  },
  getters: {},

  mutations: {
    SET_MY_NAME(state: Nullable, data: Nullable) {
      state.name = data;
      localStorage.setItem("name", data);
    },
    SET_MY_PHONE(state: Nullable, data: Nullable) {
      state.phone = data;
      localStorage.setItem("phone", data);
    },
    SET_MY_POSTCODE(state: Nullable, data: Nullable) {
      state.postcode = data;
      localStorage.setItem("postcode", data);
    },
    SET_MY_ROADADDRESS(state: Nullable, data: Nullable) {
      state.roadAddress = data;
      localStorage.setItem("roadAddress", data);
    },
    SET_MY_JIBUNADDRESS(state: Nullable, data: Nullable) {
      state.jibunAddress = data;
      localStorage.setItem("jibunAddress", data);
    },
    SET_MY_DETAILADDRESS(state: Nullable, data: Nullable) {
      state.detailAddress = data;
      localStorage.setItem("detailAddress", data);
    },
    SET_MY_EXTRAADDRESS(state: Nullable, data: Nullable) {
      state.extraAddress = data;
      localStorage.setItem("extraAddress", data);
    },
  },
  actions: {},
};
