import { getItem, setItem } from "@/utils/localStorage";
import authApi from "@/api/auth";
import { updateUserDidApi } from "@/api/did";
import router from "@/router";
import { ethers } from "ethers-ts";
import openSSLCrypto from "@/utils/openSSLCrypto";
import { bannerListApi, nftListApi, getBalanceAll, getUserBalance } from "@/api/axios";
import { sendFcmTokenApi } from "@/api/auth";
import { createDIDWithDocument } from "@/utils/did";

export default {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem("accessToken") || "",
    expireAccessToken: localStorage.getItem("expireAccessToken") || 0,
    refreshToken: localStorage.getItem("refreshToken") || "",
    expireRefreshToken: localStorage.getItem("expireRefreshToken") || 0,
    scanners: localStorage.getItem("scanners") || "",
    userId: localStorage.getItem("userId") || "",
    userName: localStorage.getItem("userName") || "",
    userEmail: localStorage.getItem("userEmail") || "",
    userAccount: localStorage.getItem("userAccount") || "",
    privateKey: localStorage.getItem("privateKey") || "",
    address: localStorage.getItem("address") || "",
    balances: localStorage.getItem("balances") || "",
    initBalance: localStorage.getItem("initBalance") || "",
    bannerList: localStorage.getItem("bannerList") || "",
    terms: localStorage.getItem("terms") || "",
    referral: localStorage.getItem("referral") || "",
    withdrawPoint: localStorage.getItem("withdrawPoint") || 0,
    popupDate: localStorage.getItem("popupDate") || "",
    bannerLatestTime: localStorage.getItem("bannerLatestTime") || 0,
    nftList: localStorage.getItem("nftList") || "",
    theNft: getItem("theNft") || {},
    nftLatestAt: getItem("nftLatestAt") || {},
    nftLatestTime: localStorage.getItem("nftLatestTime") || 0,
    mongoliaImage: getItem("mongoliaImage") || "",
    nftObj: getItem("nftObj") || {},
    conferenceBanner: getItem("conferenceBanner") || "",
    isTutorialSkipped: getItem("isTutorialSkipped") || false,
    registeredReferral: getItem("registeredReferral") || "",
  },
  getters: {
    getAccessToken: (state: Nullable) => {
      return state.accessToken;
    },
    getRefreshToken: (state: Nullable) => {
      return state.refreshToken;
    },
    getExpireAccessToken: (state: Nullable) => {
      return state.expireAccessToken;
    },
    getExpireRefreshToken: (state: Nullable) => {
      return state.expireRefreshToken;
    },
    getScanners: (state: Nullable) => {
      if (state.scanners !== "") {
        return JSON.parse(state.scanners);
      } else {
        return "";
      }
    },
    getReferral: (state: Nullable) => {
      return state.referral;
    },
    getUserId: (state: Nullable) => {
      return state.userId;
    },
    getUserName: (state: Nullable) => {
      return state.userName;
    },
    getUserEmail: (state: Nullable) => {
      return state.userEmail;
    },
    getUserAccount: (state: Nullable) => {
      return state.userAccount;
    },
    getPrivateKey: (state: Nullable) => {
      return state.privateKey;
    },
    getAddress: (state: Nullable) => {
      return state.address;
    },
    getBalances: (state: Nullable) => {
      if (state.balances !== "") {
        return JSON.parse(state.balances);
      } else {
        return "";
      }
    },
    getNftList: (state: Nullable) => {
      if (state.nftList !== "") {
        return JSON.parse(state.nftList);
      } else {
        return "";
      }
    },

    getBannerList: (state: Nullable) => {
      if (state.bannerList !== "") {
        return JSON.parse(state.bannerList);
      } else {
        return "";
      }
    },
    getTerms: (state: Nullable) => {
      return state.terms;
    },
    getWithdrawPoint: (state: Nullable) => {
      return state.withdrawPoint;
    },
    getIsTutorialSkipped: (state: Nullable) => {
      return state.isTutorialSkipped;
    },
    getRegisteredReferral: (state: Nullable) => {
      return state.registeredReferral;
    },
  },
  mutations: {
    setClearToken(state: Nullable) {
      state.expireAccessToken = 0;
      state.expireRefreshToken = 0;
      state.accessToken = "";
      state.refreshToken = "";
      localStorage.clear();
      localStorage.setItem("nftList", state.nftList);
      localStorage.setItem("bannerList", state.bannerList);
      setItem("nftLatestAt", state.nftLatestAt);
      setItem("theNft", state.theNft);
      localStorage.setItem("nftLatestTime", state.nftLatestTime);
      localStorage.setItem("popupDate", state.popupDate);
      setItem("isTutorialSkipped", state.isTutorialSkipped);
    },
    setInitToken(state: Nullable) {
      state.expireAccessToken = 0;
      state.expireRefreshToken = 0;

      localStorage.setItem("expireAccessToken", "0");
      localStorage.setItem("expireRefreshToken", "0");
    },
    setAccessToken(state: Nullable, { token, expireAt }: Nullable) {
      const currentDate = new Date().getTime() / 1000;

      state.accessToken = token;
      state.expireAccessToken = currentDate + expireAt;

      localStorage.setItem("accessToken", token);
      localStorage.setItem("expireAccessToken", currentDate + expireAt);
    },
    setRefreshToken(state: Nullable, { token, expireAt }: Nullable) {
      const currentDate = new Date().getTime() / 1000;

      state.refreshToken = token;
      state.expireRefreshToken = currentDate + expireAt;

      localStorage.setItem("refreshToken", token);
      localStorage.setItem("expireRefreshToken", currentDate + expireAt);
    },
    setScanners(state: Nullable, { info }: Nullable) {
      state.scanners = JSON.stringify(info);

      localStorage.setItem("scanners", JSON.stringify(info));
    },
    setUserId(state: Nullable, { userId }: Nullable) {
      state.userId = userId;

      localStorage.setItem("userId", userId);
    },
    setUserName(state: Nullable, { userName }: Nullable) {
      state.userName = userName;

      localStorage.setItem("userName", userName);
    },
    setUserEmail(state: Nullable, { userEmail }: Nullable) {
      state.userEmail = userEmail;

      localStorage.setItem("userEmail", userEmail);
    },
    setUserAccount(state: Nullable, { userAccount }: Nullable) {
      state.userAccount = userAccount;

      localStorage.setItem("userAccount", userAccount);
    },
    setReferral(state: Nullable, { referral }: Nullable) {
      state.referral = referral;
      localStorage.setItem("referral", referral);
    },

    setPrivateKey(state: Nullable, { privateKey }: Nullable) {
      state.privateKey = privateKey;

      localStorage.setItem("privateKey", privateKey);
    },
    setAddress(state: Nullable, { address }: Nullable) {
      state.address = address;

      localStorage.setItem("address", address);
    },
    setBalances(state: Nullable, { balance }: Nullable) {
      state.balances = balance;

      localStorage.setItem("balances", balance);
    },
    setInitBalance(state: Nullable, { initBalance }: Nullable) {
      state.initBalance = initBalance;
      localStorage.setItem("initBalance", initBalance);
    },
    setNftLatestAt(state: Nullable, payload: Nullable) {
      state.nftLatestAt = payload;
      setItem("nftLatestAt", payload);
    },

    setNftLatestTime(state: Nullable, payload: Nullable) {
      state.nftLatestTime = payload;
      localStorage.setItem("nftLatestTime", payload);
    },
    setNftList(state: Nullable, { info }: Nullable) {
      state.nftList = JSON.stringify(info);

      localStorage.setItem("nftList", JSON.stringify(info));
    },

    setTheNft(state: Nullable, { idx, data }: Nullable) {
      state.theNft = {
        ...state.theNft,
        [idx]: data,
      };

      setItem("theNft", state.theNft);
    },
    setNftUpdatedAtReUpdate(state: Nullable, { idx, data }: Nullable) {
      state.nftLatestAt = {
        ...state.nftLatestAt,
        [idx]: data,
      };

      setItem("nftLatestAt", state.nftLatestAt);
    },
    setBannerLatestTime(state: Nullable, payload: Nullable) {
      state.bannerLatestTime = payload;
      localStorage.setItem("bannerLatestTime", payload);
    },
    setBannerList(state: Nullable, { info }: Nullable) {
      state.bannerList = JSON.stringify(info);
      localStorage.setItem("bannerList", JSON.stringify(info));
    },

    setTerms(state: Nullable, { terms }: Nullable) {
      state.terms = terms;

      localStorage.setItem("terms", terms);
    },
    SET_WITHDRAWPOINT(state: any, payload: any) {
      state.withdrawPoint = payload;
      localStorage.setItem("withdrawPoint", payload);
    },
    SET_POPUP_DATE(state: any, payload: any) {
      state.popupDate = payload;
      localStorage.setItem("popupDate", payload);
    },

    SET_MONGOLIA_IMAGE(state: any, payload: any) {
      state.mongoliaImage = payload;
      localStorage.setItem("mongoliaImage", payload);
    },
    SET_NFT_OBJ(state: any, payload: any) {
      state.nftObj = payload;
      setItem("nftObj", payload);
    },
    SET_CONFERENCE_BANNER(state: any, payload: any) {
      state.conferenceBanner = payload;

      setItem("conferenceBanner", payload);
    },
    SET_TUTORIAL_COMPLETED(state: any, payload: any) {
      state.isTutorialSkipped = payload;
      setItem("isTutorialSkipped", payload);
    },
    SET_REGISTERED_REFERRAL(state: any, registeredReferral: Nullable) {
      state.registeredReferral = registeredReferral;
      localStorage.setItem("registeredReferral", registeredReferral);
    },
  },
  actions: {
    handleDiDResponse(context: Nullable, didResponse: Nullable) {
      if (didResponse.error) {
        throw new Error("DID 생성 실패: " + JSON.stringify(didResponse.error));
      }

      const { did, privateKey } = didResponse;
      return updateUserDidApi(did, privateKey);
    },
    handleRecordResponse(context: Nullable, recordResponse: Nullable) {
      if (recordResponse.status === 200) {
        console.log("DID 정보 업데이트 성공");
      } else {
        throw new Error("DID 정보 업데이트 실패: " + JSON.stringify(recordResponse.data));
      }
    },
    async processLoginAndSaveResponse(context: Nullable, userData: Nullable) {
      context.state.isLoading = true;
      const response = userData.response;

      try {
        context.commit("setAccessToken", {
          token: response.data.data.accessToken,
          expireAt: response.data.data.accessExpiresIn,
        });
        context.commit("setRefreshToken", {
          token: response.data.data.refreshToken,
          expireAt: response.data.data.refreshExpiresIn,
        });
        context.commit("setUserId", {
          userId: response.data.data.uid,
        });
        context.commit("setAddress", {
          address: response.data.data.wallet.address,
        });
        context.commit("setReferral", {
          referral: response.data.data.referral,
        });
        context.commit("setBalances", {
          balance: response.data.data.userPoint,
        });
        context.commit("setUserName", {
          userName: response.data.data.username,
        });
        context.commit("SET_REGISTERED_REFERRAL", response.data.data.registeredReferral);

        // 사용자의 DID 가 없는 경우.
        if (!response.data.data.did) {
          const uid = response.data.data.uid;

          createDIDWithDocument(uid)
            .then((didResponse) => context.dispatch("handleDiDResponse", didResponse))
            .then((recordResponse) => context.dispatch("handleRecordResponse", recordResponse))
            .catch((error) => {
              console.error(error);
            });
        }

        const seed = openSSLCrypto.decode(response.data.data.wallet.seed);
        const walletData = ethers.Wallet.fromMnemonic(seed);
        const privateKey = openSSLCrypto.encode(walletData.privateKey);
        context.commit("setPrivateKey", {
          privateKey,
        });

        context.commit("setTerms", {
          terms: response.data.data.terms,
        });

        if (userData.fcmToken) {
          const response = await sendFcmTokenApi({
            fcmToken: userData.fcmToken,
          });

          if (response.data.status === 0) {
            alert("다시시도해주세요");
            return;
          }
        }

        if (response.data.data.terms == 0) {
          router.push("/terms");
        } else {
          router.push("/" + process.env.VUE_APP_FIRST_URL);
        }
      } catch (error) {
        console.error("error");
      } finally {
        context.state.isLoading = false;
      }
    },

    async socialLogin(context: Nullable, { token, fcmToken }: Nullable) {
      context.state.isLoading = true;
      try {
        const response = await authApi.socialLogin(token, fcmToken);

        if (response.status === 200) {
          await context.dispatch("processLoginAndSaveResponse", { response });
        }
      } catch (e) {
        router.push("/login");
      } finally {
        context.state.isLoading = false;
      }
    },

    async updateDaeguLogin(context: Nullable, response: Nullable) {
      context.state.isLoading = true;
      try {
        window.flutter_inappwebview.callHandler("getFcmToken").then((res) => {
          if (!res) {
            return;
          }

          context.dispatch("processLoginAndSaveResponse", {
            response,
            fcmToken: res,
          });
        });
      } catch (e) {
        alert("e" + e);
        router.push("/login");
      } finally {
        context.state.isLoading = false;
      }
    },

    updateRefreshToken(context: Nullable) {
      authApi
        .updateRefreshToken()
        .then((response) => {
          if (response.status === 200) {
            context.commit("setAccessToken", {
              token: response.data.data.accessToken,
              expireAt: response.data.data.accessExpiresIn,
            });
          }
        })
        .catch((e) => {
          router.push("/login");
        });
    },

    updateExpire(context: Nullable) {
      context.commit("setAccessToken", "");
      router.push("/login");
    },

    withdrawPoint(context: any, point: any) {
      context.commit("SET_WITHDRAWPOINT", point);
    },

    async popupTodayDate(context: Nullable, date: Nullable) {
      context.commit("SET_POPUP_DATE", date);
    },

    async getPointBalance(context: Nullable) {
      try {
        const response = await getBalanceAll();

        if (response.status === 200) {
          const resData = response.data.data.balances;
          const balancesData: any = {};

          resData.forEach((res: any) => {
            // symbol이 "ESGP"인 경우에만 처리를 진행합니다.
            if (res.symbol === "ESGP") {
              balancesData[res.symbol] = res;
            }
          });

          context.commit("setBalances", {
            balance: balancesData.ESGP.balance,
          });

          return balancesData.ESGP.balance;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getUserBalance(context: Nullable) {
      try {
        const response = await getUserBalance();
        console.log("🚀 ~ getUserBalance ~ response:", response);

        if (response.status === 200) {
          const sumBalance = response.data.data.balance.sumBalance;
          const initBalance = response.data.data.balance.initBalance;

          context.commit("setBalances", {
            balance: sumBalance,
          });
          context.commit("setInitBalance", {
            initBalance: initBalance,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getBannerList(context: Nullable) {
      context.state.isLoading = true;

      try {
        const response = await bannerListApi();

        if (response.status === 200) {
          const bannerListData = response.data.data;

          const bannerList: any = {};
          const updatedAt: any = [];
          let latestUpdatedAt = 0;

          bannerListData.forEach((res: any) => {
            bannerList[res.idx] = res;

            updatedAt.push(Date.parse(res.updatedAt));

            if (updatedAt) {
              latestUpdatedAt = Math.max(...updatedAt);
            }
          });
          context.commit("setBannerLatestTime", latestUpdatedAt);
          context.commit("setBannerList", { info: bannerList });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        context.state.isLoading = false;
      }
    },

    async getNftList(context: Nullable) {
      context.state.isLoading = true;

      try {
        const response = await nftListApi();

        if (response.status === 200) {
          const nftListData = response.data.data;

          const nftList: any = {};
          const updatedAt: any = {};
          const updatedTime: any = [];
          let latestUpdatedTime: any = 0;

          nftListData.forEach((res: any) => {
            nftList[res.idx] = res;

            updatedTime.push(Date.parse(res.nftUpdatedTime));
            updatedAt[res.idx] = Date.parse(res.updatedAt);

            if (updatedTime) {
              latestUpdatedTime = Math.max(...updatedTime);
            }

            if (res.metaData !== "" && res.metaData !== undefined) {
              nftList[res.idx]["metaData"] = JSON.parse(res.metaData);
            } else {
              nftList[res.idx]["metaData"] = "";
            }
          });

          context.commit("setNftLatestAt", updatedAt);
          context.commit("setNftLatestTime", latestUpdatedTime);
          context.commit("setNftList", { info: nftList });
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        context.state.isLoading = false;
      }
    },
  },
};
