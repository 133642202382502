export const logout = {
  button: "logout",
};

export const logouts = [
  {
    name: logout.button,
    label: "Logout",
  },
];
