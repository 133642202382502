import http from "@/api/http";

export const bannerListApi = () => {
  return http.get("/api/banners", { params: {} });
};

export const bannerUpdatedAtApi = () => {
  return http.get("/api/bannersTime");
};

export const checkBannerLatestTime = async (vuexStore: any, bannerLatestTime: any) => {
  const response = await bannerUpdatedAtApi();

  if (response.status !== 200) {
    return;
  }

  const checkBannerLatestTime = Date.parse(response.data.data.updatedAt);
  if (checkBannerLatestTime === bannerLatestTime) {
    return;
  }
  vuexStore.dispatch("auth/getBannerList");
};

//////////////////////////
const nftUpdatedAtApi = (idx: number) => http.get(`/api/nft/zeroNftUpdatedAt/${idx}`);

const nftOneApi = (idx: number) => http.get(`/api/nft/zeroNftOne/${idx}`);

export const checkNftLatestAt = async (store: any, idx: any, nftLatestAt: any) => {
  const latestNftUpdatedAt = await nftUpdatedAtApi(idx);

  if (latestNftUpdatedAt.data.message !== "Success") {
    return;
  }

  const timeStamp = Date.parse(latestNftUpdatedAt.data.data.updatedAt);

  if (timeStamp !== nftLatestAt) {
    const nftOne = await nftOneApi(idx);

    if (nftOne.data.message === "Success") {
      await store.commit("auth/setTheNft", { idx, data: nftOne.data.data });
      await store.commit("auth/setNftUpdatedAtReUpdate", {
        idx,
        data: timeStamp,
      });
    }
  }
};

export const nftListApi = () => {
  return http.get("/api/nft/zeroNft", { params: {} });
};

const nftUpdatedTimeApi = () => {
  return http.get("/api/nft/zeroNftTime");
};

export const checkNftLatestTime = async (vuexStore: any, nftLatestTime: any) => {
  const response = await nftUpdatedTimeApi();

  if (response.status !== 200) {
    return;
  }

  const checkBannerLatestTime = Date.parse(response.data.data.nftUpdatedTime);

  if (checkBannerLatestTime === nftLatestTime) {
    return;
  }

  vuexStore.dispatch("auth/getNftList");
};

export const getBalanceAll = () => {
  return http.get("/api/token/balanceAll");
};

export const getUserBalance = () => {
  return http.get("/api/token/userBalance");
};

//-------------------------payments--------------------------
export const confirmPayments = (impUid: string) => {
  return http.post("/api/user/payments", { impUid: impUid });
};

export const pointIncrease = (chargingPoint: number) => {
  return http.post("/api/user/pointIncrease", { chargingPoint: chargingPoint });
};

//ranking

export const getRankingApi = () => {
  return http.get("/api/challenge/info");
};

//notion
export const getNotionTermsApi = () => {
  return http.get("/api/notionTerms");
};

export const getNotionNoticeApi = () => {
  return http.get("/api/notionNotice");
};
